<template>
  <div class="crown__flats">
    <div class="loader" v-if="startLoader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <div
        class="fixed-overlay-disclaimer fixed-overlay__modal-disclaimer disclaimer"
        v-if="disclaimer == true"
    >
      <div class="modal-disclaimer">
        <div class="modal_container-disclaimer">
          <h3>Hinweis</h3>
          <p>
            Bei der in diesem Konfigurator dargestellten Immobilie handelt es
            sich um ein in der Planung bzw. noch im Bau befindliches Objekt
          </p>
          <p>
            Alle Angaben, Illustrationen und Visualisierungen wurden mit größter
            Sorgfalt zusammengestellt, dennoch stellen sie einen unverbindlichen
            und vorläufigen Planungsstand dar.
          </p>
          <p>
            Die konkrete Lage, Ausrichtung und Ausstattung der Wohnungen ist
            ausschließlich der Vertragsdokumentation (Kaufvertrag nebst Anlagen)
            zu entnehmen.
          </p>
          <p>
            Die in den Plänen und in den Visualisierungen dargestellte
            Dekoration und Möblierung dient lediglich der Veranschaulichung und
            gehört nicht zum Kaufgegenstand.
          </p>
          <button v-on:click="sendAkzeptieren(true)">Akzeptieren</button>
          <button v-on:click="sendAkzeptieren(false)">Ablehnen</button>
        </div>
      </div>
    </div>

    <img
        class="crown__flats--background"
        src="./../assets/flats/flats-background-min.jpg"
        alt=""
    />
    <Menu/>
    <div
        v-if="sudost"
        :class="
        visible && sudost ? 'crown__flats--house-none' : 'crown__flats--house'
      "
    >
      <svg
          version="1.1"
          id="sudost"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 437 712"
          style="enable-background: new 0 0 437 712;"
          xml:space="preserve"
      >
        <g id="Слой_3">
          <image
              style="overflow: visible"
              width="437"
              height="712"
              href="./../assets/flats/sud-ost.png"
          ></image>
        </g>

        <g id="fr14ft2" v-if="statusFlat('14.02')">
          <polygon
              :class="active_flat == '14.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('14.02');
            "
              points="313.966 145.248 313.19 176.688 227.497 145.96 61.617 188.928 61.617 155.928 228.637 99.44"
          />
          <title>14.02</title>
        </g>
        <g id="fr15ft2" v-if="statusFlat('15.02')">
          <polygon
              :class="active_flat == '15.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('15.02');
            "
              points="320.108 77.608 314.431 147.384 228.174 107.512 56.373 156.64 55.058 107.512 75.155 101.104 73.034 78.792 225.912 22.784"
          >
            <title>15.02</title>
          </polygon>
        </g>
      </svg>
      <div
          v-if="flatModal"
          class="modal__back fixed-overlay__modal fixed-overlay__modal::after"
      >
        <div class="modal">
          <div class="modal_container">
            <div class="modal_container--close">
              <img
                  v-on:click="
                  flatModal = false;
                  active_flat = false;
                "
                  src="./../assets/close.png"
                  alt=""
              />
            </div>
            <p>
              <span
              >Wohnung
                <span>{{name}}</span>
              </span>
            </p>
            <p>{{ floor[0] }}.Etage</p>
            <p v-if="RoomCount != 0">{{ RoomCount }} Zimmer</p>
            <p v-if="RoomCount == 0">Zimmer Keine Information</p>
            <p v-if="Square != 0">Gesamtwohnfläche {{ Square }} m²</p>
            <p v-if="Square == 0">Gesamtwohnfläche Keine Information</p>
            <p>Noch verfügbar!</p>
            <button
                class="modal_container--button outline-none"
                v-on:click="clickFlat()"
            >
              Jetzt Kontakt aufnehmen
            </button
            >
            <br/>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="!sudost"
        :class="
        visible && !sudost ? 'crown__flats--house-none' : 'crown__flats--house'
      "
    >
      <svg
          version="1.1"
          id="nord-west"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 437 712"
          style="enable-background: new 0 0 437 712"
          xml:space="preserve"
      >
        <g id="Слой_3">
          <image
              style="overflow: visible"
              width="437"
              height="712"
              href="./../assets/flats/nord-west.png"
          ></image>
        </g>
<!--        <g id="fr14ft2" v-if="statusFlat('14.02')">-->
<!--          <polygon-->
<!--              :class="active_flat == '14.02' ? 'st0 active-flat' : 'st0'"-->
<!--              v-on:click="-->
<!--              flatModal = !flatModal;-->
<!--              flatHouse('14.02');-->
<!--            "-->
<!--              points="21.3,215.4 21.3,189.6 270,118.8 348.3,172 348.3,197.5 270.1,149.3 	"-->
<!--          />-->
<!--          <title>14.02</title>-->
<!--        </g>-->
<!--        <g id="fr15ft02" v-if="statusFlat('15.02')">-->
<!--          <polygon-->
<!--              :class="active_flat == '15.02' ? 'st0 active-flat' : 'st0'"-->
<!--              v-on:click="-->
<!--              flatModal = !flatModal;-->
<!--              flatHouse('15.02');-->
<!--            "-->
<!--              points="51.2,174.8 51.2,118.8 93,110.7 93,101.1 173.8,86.7 173.8,74.9 276.1,57.5 276.1,83.2 307.2,79.3 307.2,137.7-->
<!--		269.6,110.7 	"-->
<!--          >-->
<!--            <title>15.02</title>-->
<!--          </polygon>-->
<!--        </g>-->
      </svg>

      <div
          v-if="flatModal"
          class="modal__back fixed-overlay__modal fixed-overlay__modal::after"
      >
        <div class="modal">
          <div class="modal_container">
            <div class="modal_container--close">
              <img
                  v-on:click="
                  flatModal = false;
                  active_flat = false;
                "
                  src="./../assets/close.png"
                  alt=""
              />
            </div>
            <p>
              <span
              >Wohnung
                <span v-if="name != '15.01' && name != '15.02'">{{
                    name
                  }}</span>


                <span v-if="name == '15.02'">{{ name }} - 16.02</span>
              </span>
            </p>
            <p>{{ floor[0] }}.Etage</p>
            <p v-if="RoomCount != 0">{{ RoomCount }} Zimmer</p>
            <p v-if="RoomCount == 0">Zimmer Keine Information</p>
            <!--<p>Ausblick Nord-West</p>-->
            <p v-if="Square != 0">Gesamtwohnfläche {{ Square }} m²</p>
            <p v-if="Square == 0">Gesamtwohnfläche Keine Information</p>
            <p>Noch verfügbar!</p>
            <button
                class="modal_container--button"
                v-on:click="clickFlat()"
            >
              Jetzt Kontakt aufnehmen
            </button
            >
            <br/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="visible" class="crown__flats--flats-table">
      <div class="table__head">
        <div class="table__head--name">Wohnungsfinder</div>
        <div
            v-on:click="visible = !visible"
            class="table__head--button pointer"
        >
          Wohnungsliste &#9660;
        </div>
      </div>
      <section>
        <header class="header-padding">
          <div class="col">Wohnung</div>
          <div class="col">Etage</div>
          <div class="col">Zimmer</div>
          <div class="col">Größe</div>
          <div class="col">Ausblick</div>
<!--          <div class="col">Grundriss</div>-->
          <div class="col">Kaufpreis</div>
          <div class="col">Personalisieren</div>
        </header>
        <div class="row-padding">
          <div
              v-for="(flat, index) in arr_flats_name"
              :key="index"
          >
            <div
                v-if="flat.status === 0"
                :class="
              flat.name == '15.01' || flat.name == '15.02'
                ? 'active-line-none'
                : ''"
                class="row active-line"
            >
            <div class="col">
              {{ flat.name }}
            </div>
            <div class="col">{{ flat.floor.toFixed(0) }} OG</div>
            <div class="col">
              <span v-if="flat.RoomCount != 0">
                {{ flat.RoomCount }}
              </span>
              <span v-if="flat.RoomCount == 0"> - </span>
            </div>
            <div class="col">
              <span v-if="flat.Square != 0">
                {{ changeSquare(flat.Square) }} m²
              </span>
              <span v-if="flat.Square == 0"> - </span>
            </div>

            <div class="col">{{ flat.side }}</div>

<!--            <div class="col">-->
<!--              <span-->
<!--                  class="pointer"-->
<!--                  v-if="flat.status == 0"-->
<!--                  v-on:click="clickFlatGrundriss(flat.key, flat.name, flat.key)"-->
<!--              >-->
<!--                Grundriss-->
<!--              </span>-->
<!--              <span v-if="flat.status == 1"> - </span>-->
<!--            </div>-->
              <div class="col">
              <span v-if="flat.status == 0">
                {{ editSum(Number(flat.cost)) }}
              </span>
                <span v-if="flat.status == 1"> &ensp; </span>
              </div>
            <div class="col">
              <span
                  class="pointer"
                  v-if="flat.status == 0"
                  v-on:click="clickFlat()"
              >
                Verfügbar
              </span>
              <span v-if="flat.status == 1"> Verkauft </span>
              <span v-if="flat.status == 2"> Reserviert </span>
            </div>
          </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="!visible" class="crown__flats--flats-table">
      <div class="none-padding"></div>
      <div class="table__head">
        <div class="table__head--name">Wohnungsfinder</div>
        <div class="crown__house--side-homeside">
          <button
              v-on:click="sudost = true"
              class="side-button pointer-side"
              :class="sudost ? 'side-button-active' : ''"
          >
            Ansicht: Süd-Ost
          </button>
          &nbsp; | &nbsp;
          <button
              v-on:click="sudost = false"
              class="side-button pointer-side"
              :class="!sudost ? 'side-button-active' : ''"
          >
            Ansicht: Nord-West
          </button>
        </div>
        <div
            v-on:click="visible = !visible"
            class="table__head--button pointer"
        >
          Wohnungsliste &#9650;
        </div>
      </div>
    </div>
    <div class="crown__flats--footer">
      <img
          v-if="sudost"
          src="./../assets/flats/shadow.png"
          alt=""
      />
      <img v-if="!sudost" src="./../assets/flats/shadow-nord.png" alt=""/>
    </div>
  </div>
</template>

<script>
import Menu from './../components/Menu'
import axios from 'axios'
import moment from 'moment'
import {mapGetters} from "vuex";

export default {
  data: function () {
    return {
      startLoader: true,
      disclaimer: false,
      house: {},
      floor: '',
      floors: {},
      flats: {},
      visible: false,
      flatModal: false,
      sudost: true,
      active_flat: '',
      rooms: {},
      arr: [],
      arr_flats: [],
      temp: {},
      bd: {},
      arrFloors: {},
      arr_flats_name: [],
      name: '',
      key: '',
      RoomCount: '',
      Square: '',
      sideImg: {}
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  components: {
    Menu
  },
  methods: {
    changeSquare(square) {
      let p = square.toFixed(2)
      let str = new String(p);
      str = str
          .replace(/\./g, ',')

      if (Number.isInteger(square) == true)
        return square + ',00'
      else
        return str
    },
    editSum(sum) {
      var value = (Number(sum)).toLocaleString("en-US", {
        style: "currency",
        currency: "EUR",
      })
      var str = new String(value);
      str = str
          .replace(/,/g, "__COMMA__") // Replace `,` by some unique string
          .replace(/\./g, ',')         // Replace `.` by `,`
          .replace(/__COMMA__/g, '.'); // Replace the string by `.`
      str = str.replace(/€/g, '');
      str = str + ' €'
      return str
    },
    clickFlat() {
      this.$router.push({ name: 'Kontakt' });
    },

    flatHouse(flat) {
      this.active_flat = flat
      this.floor = 'none'
      this.name = 'none'
      this.key = 'none'

      this.RoomCount = 'none'
      this.Square = 'none'


      for (let i = 0; i < this.arr_flats_name.length; i++) {
        if (this.arr_flats_name[i].name == flat) {
          this.name = this.arr_flats_name[i].name;
          this.key = this.arr_flats_name[i].key;

          this.RoomCount = this.arr_flats_name[i].RoomCount
          this.Square = this.arr_flats_name[i].Square
        }
      }
      this.floor = String(this.name).split(".");
    },

    sortedUsers(arr) {
      return arr.sort((a, b) => a.name > b.name ? 1 : -1);
    },
    statusFlat(flat) {
      if (this.user.loggedIn == true)
        if (this.user.data.displayName == 'Superadmin')
          return true

      if (this.user.loggedIn == false) {
        for (let key in this.arr_flats_name) {
          if (this.arr_flats_name[key].name == flat && this.arr_flats_name[key].status == 0)
            return true
          if (this.arr_flats_name[key].name == flat && this.arr_flats_name[key].status == 1)
            return false
        }
      }
    },

    sendAkzeptieren(action) {
      if (action) {
        if (this.$store.getters.user.loggedIn == false) {
          let url = 'https://us-central1-cadman-63dc7.cloudfunctions.net/setDisclimer?localStorage=' + localStorage.getItem('crown') + '&date=' + moment().unix()
          axios.get(url)
              .then((response) => {
                console.log(response)
              })
              .finally(() => {
                this.disclaimer = false
              })
        }
        if (this.$store.getters.user.loggedIn == true) {
          let urlUser = 'https://us-central1-cadman-63dc7.cloudfunctions.net/setAuthDisclimer?localStorage=' + localStorage.getItem('crown') + '&date=' + moment().unix()
          axios.get(urlUser)
              .then((response) => {
                console.log(response)
              })
              .finally(() => {
                this.disclaimer = false
              })
        }
      }
      if (!action) {
        this.$router.push('/').catch(() => {
        });
      }
    }
  },
  mounted() {

    axios.get('https://cadman-63dc7.firebaseio.com/ImageData/.json')
        .then((response) => {
          this.sideImg = response.data
        })

    this.visible = false
    let urlAnonim = 'https://us-central1-cadman-63dc7.cloudfunctions.net/getDisclimer?localStorage=' + localStorage.getItem('crown')
    let urlUser = 'https://us-central1-cadman-63dc7.cloudfunctions.net/getAuthDisclimer?localStorage=' + localStorage.getItem('crown')
    var q = null
    this.disclaimer = false
    this.startLoader = true

    axios.get('https://cadman-63dc7.firebaseio.com/Houses/.json')
        .then((response) => {
          this.houses = response.data
        })
        .finally(() => {
          axios.get('https://cadman-63dc7.firebaseio.com/Floors.json')
              .then((response) => {
                this.floors = response.data
              })
              .finally(() => {
                axios.get('https://cadman-63dc7.firebaseio.com/Flats.json')
                    .then((response) => {
                      this.flats = response.data
                    })
                    .finally(() => {

                      this.startLoader = true

                      if (this.user.loggedIn == false) {
                        axios.get(urlAnonim)
                            .then((response) => {
                              q = response.data
                            })
                            .finally(() => {
                              if (q == true) {
                                this.disclaimer = false
                              }
                              if (q == false) {
                                this.disclaimer = true
                              }

                              for (var key in this.houses) {
                                if (key == 'f1878f21-6f48-4791-a11f-196471f49c5d')
                                  this.arr = this.houses[key].Data.SubEntitiesIds
                              }
                              for (var key_floors in this.floors) {
                                for (let i = 0; i < this.arr.length; i++) {
                                  if (this.arr[i] == key_floors)
                                    for (let j = 0; j < this.floors[key_floors].Data.SubEntitiesIds.length; j++) {
                                      this.arr_flats.push(this.floors[key_floors].Data.SubEntitiesIds[j])
                                    }
                                }
                              }
                              for (let i = 0; i < this.arr_flats.length; i++) {
                                for (var key_flats in this.flats) {
                                  if (key_flats == this.arr_flats[i]) {

                                    this.arr_flats_name.push({
                                      key: key_flats,
                                      name: Number(this.flats[key_flats].Data.displayName),
                                      status: this.flats[key_flats].Data.State,
                                      floor: Number(this.flats[key_flats].Data.displayName),
                                      RoomCount: this.flats[key_flats].Data.RoomCount,
                                      Square: this.flats[key_flats].Data.Square,
                                      cost: this.flats[key_flats].Data.UDID,
                                      side: 'SÜDWESTOST'
                                    })
                                  }
                                }
                              }
                              this.startLoader = false
                              this.sortedUsers(this.arr_flats_name)

                              let el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 3
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);


                            })
                      }

                      if (this.user.loggedIn == true && this.user.data.displayName == 'Superadmin') {
                        axios.get(urlUser)
                            .then((response) => {
                              q = response.data
                            })
                            .finally(() => {
                              if (q == true) {
                                this.disclaimer = false
                              }
                              if (q == false) {
                                this.disclaimer = true
                              }

                              for (var key in this.houses) {
                                if (key == 'f1878f21-6f48-4791-a11f-196471f49c5d')
                                  this.arr = this.houses[key].Data.SubEntitiesIds
                              }
                              for (var key_floors in this.floors) {
                                for (let i = 0; i < this.arr.length; i++) {
                                  if (this.arr[i] == key_floors)
                                    for (let j = 0; j < this.floors[key_floors].Data.SubEntitiesIds.length; j++) {
                                      this.arr_flats.push(this.floors[key_floors].Data.SubEntitiesIds[j])
                                    }
                                }
                              }
                              for (let i = 0; i < this.arr_flats.length; i++) {
                                for (var key_flats in this.flats) {
                                  if (key_flats == this.arr_flats[i]) {

                                    this.arr_flats_name.push({
                                      key: key_flats,
                                      name: Number(this.flats[key_flats].Data.displayName),
                                      status: 0,
                                      floor: Number(this.flats[key_flats].Data.displayName),
                                      RoomCount: this.flats[key_flats].Data.RoomCount,
                                      Square: this.flats[key_flats].Data.Square,
                                      cost: this.flats[key_flats].Data.UDID,
                                      side: 'SÜDWESTOST'
                                    })
                                  }
                                }
                              }
                              this.startLoader = false
                              this.sortedUsers(this.arr_flats_name)

                              let el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 3
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);


                            })
                      }


                    })
              })
        })

  }
}
</script>

<style scoped>
button {
  padding: 0;
  margin: 1em;
  border: 0px solid #fff;
  background-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 1px #323a41;
}

.crown__flats--background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  height: 100%;
}

.crown__flats {
  width: 100%;
  text-align: center;
}

.crown__flats--flats-menu {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.crown__flats--flats-menu > div {
  padding: 0.5em;
}

.crown__flats--footer {
  position: fixed;
  height: 25%;
  bottom: 0;
  width: 100vw;
  background: rgb(50, 58, 65);
  background: linear-gradient(
      180deg,
      rgba(50, 58, 65, 1) 0%,
      rgba(50, 58, 65, 0.3) 100%
  );
  z-index: 0;
}

.crown__flats--footer img {
  height: 65vh;
  top: -1em;
  position: relative;
}

.crown__flats--flats-table {
  position: fixed;
  width: 100%;
  max-height: 60vh;
  margin: 0 auto;
  font-family: "35-FTR", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  text-decoration: none;
  bottom: 35px;
  z-index: 1;
}

.crown__flats--flats-table section {
  position: relative;
  bottom: 0.05em;
  height: calc(100% - 28px);
  width: 1178px;
  margin: 0 auto;
  background-color: #eeedec;
  overflow-y: scroll;
}

.crown__flats--flats-table .table__head {
  position: relative;
  bottom: 0.05em;
  height: 35px;
  width: 1178px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.crown__flats--flats-table .table__head--name {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
  font-size: 15px;
}

.crown__flats--flats-table .table__head--button {
  padding-right: 2em;
}

.crown__flats--flats-table header {
  font-weight: bold;
}

.row-padding {
  padding-left: 2em;
  padding-right: 2em;
}

header,
.row {
  display: flex;
  padding: 1em;
  border-bottom: 1px solid #323a41;
  padding-left: 0;
  padding-right: 0;
}

header {
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #707070;
}

.header-padding {
  padding-left: 2em;
  padding-right: 2em;
}

.col {
  flex: 1;
}

.none-padding {
  height: calc(100% - 28px);
  width: 100px;
}

.crown__house--side-homeside button {
  margin: 0;
  text-shadow: 0px 0px;
  font-size: 15px;
}

a {
  color: black;
}

a:hover {
  background-color: black;
}

.crown__flats--house {
  position: relative;
  margin: 0 auto;
  z-index: 2;
}

.crown__flats--house-none {
  margin: 0 auto;
  z-index: 2;
}

.st0 {
  opacity: 0;
}

.st0:hover {
  fill: #ad8059 !important;
  opacity: 0.7;
}

.active-flat {
  fill: #ad8059 !important;
  opacity: 0.7;
}

.fixed-overlay__modal {
  text-align: center;
  white-space: nowrap;
}

.fixed-overlay__modal::after {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  content: "";
}

.modal {
  display: inline-block;
  vertical-align: middle;
}

.modal_container {
  position: relative;
  margin: 50px;
  padding: 20px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  color: #323a41;
  text-align: center;
  font-family: "35-FTR", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  text-decoration: none;
}

.modal_container span {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1em;
}

.modal_container--button {
  margin-bottom: 1em;
  color: #323a41;
  text-shadow: 0px 0px 0px #323a41;
  font-weight: bold;
  cursor: pointer;
}

.modal_container--close {
  position: absolute;
  padding: 1em;
  right: 0;
  top: 0;
}

svg#nord-west {
  position: relative;
  height: 65vh;
  margin: 0 auto;
}

svg#sudost {
  position: relative;
  height: 65vh;
  margin: 0 auto;
}

.modal__back {
  position: absolute;
  top: 0;
  left: -50%;
  right: 0;
  bottom: 0;
}

.active-line:hover {
  background-color: #d6cec1;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: #ad8059;
}

.pointer-side {
  cursor: pointer;
}

.fixed-overlay__modal-disclaimer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  line-height: 1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(10px);
  text-align: center;
  white-space: nowrap;
}

.fixed-overlay__modal-disclaimer::after {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  content: "";
  backdrop-filter: blur(10px);
  opacity: 0.5;
}

.modal-disclaimer {
  display: inline-block;
  vertical-align: middle;
}

.modal_container-disclaimer {
  margin: 6em;
  padding: 2em;
  padding-left: 4em;
  padding-right: 4em;
  min-width: 200px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  color: #323a41;
}

.modal_container-disclaimer h3 {
  font-size: 24px;
}

.modal_container-disclaimer button {
  border: none;
  font: inherit;
  color: inherit;
  background-color: #d6cec1;
  padding: 1em;
  margin: 0;
  margin-right: 1em;
  margin-top: 2em;
  margin-bottom: 2em;
  text-shadow: 0px 0px 0px #323a41;
}

.pointer {
  cursor: pointer;
}

.outline-none {
  outline: none;
}

.info {
  z-index: 999;
  position: fixed;
}

button.side-button {
  font-weight: 300;
}

button.side-button.side-button-active {
  font-weight: 600;
}

button.side-button:focus,
button.side-button:active {
  outline: none;
}

button.side-button::-moz-focus-inner {
  border: 0;
}

.col.price {
  text-align: right;
}

.active-line-none {
  border: 0;
}
</style>
